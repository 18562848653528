import React, {useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import OutsideClickHandler from 'shared/components/Header/OutsideClickHandler';

import type {TableRow, TableButton} from 'shared/types';

type DropdownOptionType = {
    row: TableRow;
    button: TableButton;
    isSmallDevice?: boolean;
    hasTitle?: boolean;
};

const DropdownOption = ({
    row,
    button,
    hasTitle = true,
}: DropdownOptionType): JSX.Element => {
    const orderedOptions = button.dropdown.sort(
        (current, next) => current.order - next.order
    );
    const [show, setShow] = useState<boolean>(false);

    const onOutsideClickFunc = () => {
        setShow(false);
    };

    return (
        <OutsideClickHandler onOutsideClick={onOutsideClickFunc}>
            <Dropdown
                className="table-option-dropdown"
                alignRight
                drop="down"
                show={show}
                onBlur={(e: React.FocusEvent<HTMLElement>) => {
                    // only hide if click outside of dropdown
                    if (e && e.relatedTarget) {
                        e.relatedTarget.click();
                    }
                    setShow(false);
                }}>
                <Dropdown.Toggle
                    variant="link"
                    onClick={(e) => {
                        e.stopPropagation();
                        setShow(true);
                    }}
                    title={hasTitle ? button.title : ''}
                    role="table-option">
                    <Icon iconName={button.iconName} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {orderedOptions.map((option, index) => {
                        if (option.hasOwnProperty('show')) {
                            if (
                                typeof option.show === 'boolean' &&
                                !option.show
                            )
                                return <div key={index} />;

                            if (
                                typeof option.show === 'function' &&
                                !option.show(row)
                            )
                                return <div key={index} />;
                        }

                        return (
                            <Dropdown.Item
                                key={index}
                                onClick={(
                                    event: React.MouseEvent<HTMLElement>
                                ) => button.action(row, event, option.name)}
                                role="table-option-dropdown-menu">
                                {option.name}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </OutsideClickHandler>
    );
};

export default DropdownOption;
